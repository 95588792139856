@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins/clearfix";

@import "variables";
@import "labels";
@import "post-summary";
@import "overlays";
@import "forms";
@import "tables";
@import "portlets";
@import "lazyload";
@import "codemirror";
@import "comments";
@import "header";
@import "colors";
@import "plugin";
@import "layout";
@import "progressbar";
@import "progresscircle";
@import "flex-grid";
@import "dropdowns";
@import "bootbox";
@import "modal";
@import "embeds";
@import "lists";
@import "badges";
@import "buttons";
@import "switch";

@import "bt4/utilities/sizing";
@import "bt4/utilities/display";
@import "bt4/utilities/position";
@import "bt4/utilities/font";
@import "bt4/display";
@import "bt4/flex";
@import "bt4/spacing";

@import "post-facebook";
@import "publishing-post-gallery";

@import "pages/testing-index";
@import "pages/login";
@import "pages/password";
@import "pages/article";


.teaser-thumbnail {
  width: 100%;

  img {
    width: 100%;
  }

  & + .button-row {
    margin-top: 15px
  }
}


.form-control[disabled],
fieldset[disabled] .form-control,
a.disabled {
  cursor: default;
}

// BlockUI

.blockUI.blockOverlay {
  opacity: 0.4 !important;
}

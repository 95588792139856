.post-facebook {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 1.34;
  color: #1d2129;

  border-top: 1px solid #ccc;
  border: 1px solid;
  border-color: #e5e6e9 #dfe0e4 #d0d1d5;

  border-radius: 3px;
  background-color: #f2f3f5;

  .post-content {
    position: relative;
    padding: 12px;
    background-color: white;
  }

  .post-header {
    margin-bottom: 11px;
    display: flex;
  }

  .header-icon {
    vertical-align: top;
    width: 40px;
    height: 40px;
    margin-right: 8px;
    position: relative;

    a {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }

    img {
      width: 40px;
    }
  }

  .header-caption {
    vertical-align: top;

    .caption-subject {
      color: #365899;
      font-weight: bold;
      font-size: 14px;
      margin:  0 0 2px 0;
      padding-right: 22px;

      a {
        color: inherit;
      }
    }

    .caption-helper {
      color: #90949c;
    }
  }

  .user-content {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.38;

    p {
      display: inline;
      margin: 6px 0;
    }

    a, a:hover {
      color: #365899;
    }
  }

  .post-content-box {
    margin-top: 10px;

    &.type-link {
      box-shadow: 0 0 0 1.5px rgba(0, 0, 0, .1) inset, 0 1px 1px rgba(0, 0, 0, .05);
    }
  }

  .post-picture {
    position: relative;

    img {
      width: 100%;
      display: block;
    }

    &::after {
      border: 1px solid rgba(0, 0, 0, .1);
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    .overlay-link {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
    }
  }

  .video-icon {
    position: absolute;
    background: url(/static/img/facebook/icon_sprite2.png) no-repeat 0 0;
    width:72px; height: 72px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .link-content {
    padding: 10px 12px;
  }

  .text {
    font-family: Georgia,Times,Times New Roman,serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 5px;
    max-height: 110px;
    overflow: hidden;
    word-wrap: break-word;
  }

  .description {
    font-size: 12px;
    line-height: 16px;
    max-height: 80px;
    overflow: hidden;
  }

  .source {
    padding-top: 9px;
    font-size: 12px;
    line-height: 11px;
    text-transform: uppercase;
    color: #606770;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 5px;
  }


  .label {
    display: inline-block;
  }

  .post-row {
    padding: 6px 8px;
    border-top: 1px solid #e7ecf1;

    .table {
      margin-bottom: 0;

      tbody > tr {
        td, th {
          border-bottom: 1px solid transparent;
          border-top: 0 none;
          padding: 2px;
          vertical-align: top;
        }

        th + td {
          padding-left: 6px;
        }

        th {
          color: #8896a0;
        }
      }
    }
  }
}

// Post stats
.post-facebook .post-row.statbar {
  color: #7f7f7f;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;


  .statbar-item {
    display: inline-block;
    margin-right: 20px;
  }

  img.sso-icon {
    display: inline-block;
    height: 12px;
    margin-bottom: 2px;
  }
}


// Post reactions
.post-facebook .post-row.reactions {
  color: #898f9c;
  font-size: 12px;

  .reactions-types {
    display: inline-block;
    height: 16px;
    line-height: 0;
  }

  .reactions-total {
    display: inline-block;
    margin-left: 2px;
    vertical-align: top;
    line-height: 20px;
  }

  .reactions-icon {
    background-image: url(/static/img/facebook/reaction_icons_sprite.png);
    background-repeat: no-repeat;
    background-size: auto;
    width: 18px;
    height: 18px;
    display: inline-block;
    border-radius: 50%;
    border: 1px solid transparent;

    + .reactions-icon {
      margin-left: -4px;
    }

    &.react-haha { background-position: -34px -77px; }
    &.react-like { background-position: -51px -77px; }
    &.react-love { background-position: -68px -77px; }
    &.react-sorry { background-position: -102px -77px; }
    &.react-wow { background-position: -119px -77px; }
    &.react-anger { background-position: -136px -60px; }
  }
}


.post-facebook .dropdown-actions {
  position: absolute;
  right: 0;
  top: 0;
}

.post-facebook .message-bar {
  padding: 2px 12px;
  color: white;

  &.message-error {
    background-color: #e7505a;
  }

  &.message-success {
    background-color: #26a1ab;
  }
}

.facebook-post-preview {
  max-width: 500px;
}


.page-toolbar .filters {
  display: inline-block;
  margin-top: 14px;
  list-style-type: none;
  display: flex;

  li {
    display: inline-block;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }

  .btn [data-role="remove"] {
      display: none;
  }

  .btn.filter-active [data-role="remove"] {
      display: inline-block;
  }

  .btn.purple-sharp {
    &:hover,
    &:focus,
    &:active {
      background-color: inherit;
      border-color: #796799;
      color: #796799;
    }
    &.filter-active {
      background-color: #796799;
      border-color: #796799;
      color: white;
    }
  }

  .select2-container--bootstrap .select2-results__option--highlighted[aria-selected] {
      background-color: #796799;
  }

  .select2-container--bootstrap .select2-results__option[aria-selected=true] {
      background-color: rgba(121, 103, 153, 0.8);
  }

  .select2-container--bootstrap .select2-selection--single {
    border-color: #796799;
    font-weight: bold;

    .select2-selection__rendered, .select2-selection__placeholder {
      color: #796799;
    }

    .select2-selection__clear {
      color: #796799;
      font-weight: bold;
    }

    .select2-selection__arrow b {
      border-color: #796799 transparent transparent transparent;
      font-weight: bold;
    }
  }

  .filter-active + .select2-container--bootstrap .select2-selection {
    border-color: #796799;
  }

}
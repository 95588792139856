.progress-label-wrapper .progress-label-top {
  display: inline-block;
  margin-bottom: .8rem;
}

.table td .progress {
  margin-bottom: 0;
}

.progress-bar-default {
  background-color: #80C9F5;
}

.progress {
  position: relative;

  .progress-label-inner {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: #333;
  }
  .progress-bar {
    &.progress-bar-striped {
      background-image: linear-gradient(45deg,rgba(255,255,255,.5) 25%,rgba(255,255,255,.75) 25%,rgba(255,255,255,.75) 50%,rgba(255,255,255,.5) 50%,rgba(255,255,255,.5) 75%,rgba(255,255,255,.75) 75%,rgba(255,255,255,.75));
      background-size: 15px 15px;
    }
    &.progress-bar-striped.overperforming {
      background-color: #32c5d2;
    }
  }
  &.with_forecast .progress-bar {
    &:not(.forecast) {
      border-right: 1px solid rgba(0, 0, 0, .1);
    }
  }
}

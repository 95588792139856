.comment-list {
  list-style-type: none;
  padding-left: 0;

  .comment-item {
    padding-left: 60px;
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;

    &:not(:first-child) .comment-body {
        border-top: 1px solid #e4eaec;
    }
  }

  .comment-userpic {
    position: absolute;
    top: 15px;
    left: 0;

    img {
      width: 100%;
      max-width: 100%;
      border-radius: 50% !important;
      overflow: hidden;
      border: 1px solid #cedae1;
      width: 41px; height: 41px;
    }
  }

  .comment-head {
    margin-top: 5px;
    margin-bottom: 15px;
    position: relative;

    .comment-username {
      font-weight: bold;
      color: #060606;
    }

    .comment-date {
      color: #BABABA;
      color: #a3afb7;
      font-size: 12px;
    }
  }

  .comment-subject, .comment-message {
    color: #76838f;
  }
  .comment-message {
    .mention{
      font-weight: 600;
      color: #659be0;
    }
    .mention.current-user{
      background-color: #f9e491;
    }
  }

  .comment-subject {
    font-weight: bold;
    margin-bottom: 10px;
  }

  .comment-body {
    padding-top: 5px;

    p:last-child {
      margin-bottom: 0;
    }
  }

  .comment-footer {
    text-align: right;
    padding-top: 5px;
  }
}

.comment-form {
  margin-top: 20px;

  .comment-userpic {
    position: absolute;
    top: 0;
    left: 0;
    width: 41px;
    height: 41px;
    border-radius: 50% !important;
    overflow: hidden;
    border: 1px solid #cedae1;

    img {
      width: 100%;
      max-widght: 100%;
    }
  }

  .form-group {
    margin-left: 0;
    margin-right: 0;
    padding-left: 60px;
    position: relative;
  }

  .form-actions {
    padding: 0;
    border-top: 0 none;
    text-align: right;
  }
}

.portlet > .portlet-title > .actions > form > .btn {
  padding: 4px 10px;
  font-size: 13px;
  line-height: 1.5;
}

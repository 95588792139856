form .alert > p + ul {
  margin-top: 10px;
}

.has-error .errorlist {
  padding-left: 0;
}

.form-text.form-text {
  height: auto;
}

.has-error .help-block {
  color: #737373;
}

.has-error .errorlist {
  margin-top: 5px;
  margin-bottom: 5px;
  list-style-type: none;
  color: #e73d4a;
  font-weight: bold;
}

textarea[readonly] {
  resize: none;
}

.form-group .tag-button-list {
  display: flex;
  flex-wrap: wrap;

  label {
    display: block;
    margin-bottom: 5px;
    margin-right: 5px;
  }

  input {
    display: none;
  }

  input:checked + .btn.purple-sharp {
    border-color: #796799;
    color: #fff;
    background-color: #796799;
  }

  input[is-category] + .btn.purple-sharp {
    border-bottom: 3px solid #32c5d2;
  }
}

form .empty-form {
  display: none;
}

form .formset-group + .formset-group {
  border-top: 1px solid #e7ecf1;
}

form .formset-group > .form-actions:first-child {
  border-top: 0 none;
  padding-bottom: 0;
}

form .formset-entry + .form-actions {
  border-top: 0 none;
}

form .img-thumbnail + input[type="file"] {
  margin-top: 10px;
}

.form-horizontal .form-group .mt-checkbox-inline,
.form-horizontal .form-group .mt-radio-inline {
  margin-bottom: -15px;
}

.widget-splitdatetime {
  display: flex;
  flex-wrap: wrap;

  > .input-group {
    margin-bottom: 5px;
  }

  > .input-group:first-child {
      margin-right: 20px;
  }
}

textarea.form-control {
  max-width: 100%;
}

form .form-group label.field-required:after {
  content: '*';
  color: #e02222;
  font-size: 12px;
  padding-left: 2px;
}

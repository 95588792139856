.page-header .page-header-top .top-menu .navbar-nav > li.nav-icon-btn {
  margin: 0;
  padding: 0 4px;
  height: 50px;
  display: inline-block;

  a:hover, a:focus {
    background-color: transparent;
  }

  i {
    color: #C1CCD1;
    font-size: 19px;
  }

  .badge {
    font-family: "Open Sans",sans-serif;
    position: absolute;
    top: 7px;
    right: 30px;
    font-weight: 300;
    padding: 3px 6px;
  }

  .badge.badge-default {
    background-color: #f36a5a;
    color: #fff;
  }
}

/* Logo */
.page-header .page-header-top .page-logo {
  display: table;
  width: 400px;

  > a {
    display: table-cell;
    vertical-align: middle;
    max-height: 40px;
  }

  .logo-default {
    margin-top: 0;
  }

  @media (max-width: 767px) {
    width: 250px !important;
  }

  @media (max-width: 380px) {
    width: 150px !important;
  }

  @media (max-width: 250px) {
    width: auto !important;
  }
}

.page-header .page-header-top .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu {
  .dropdown-menu-list > li > a {
    padding: 5px 10px;
  }

  > li.external {
    padding: 10px;
  }
}
.page-login {
  background-color: #eff3f8;

  .btn {
    padding: 10px 25px !important;

    + .btn {
      margin-top: 20px;
    }

    &.hidden {

    }
  }

  .login-form {
    display: none;
    margin-top: 20px;

    &.active {
      display: block;
    }

    .form-control {
      height: 43px;
    }
  }

  .page-content {
    padding: 15px 15px;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    height: 100vh;

    @media (min-width: 992px) {
      padding-left: 60px;
      padding-right: 60px;
    }
  }

  .login-container {
    vertical-align: middle;
    width: 400px;
    margin: 20% auto 0 auto;

    .logo {
      display: block;
      width: 300px;
      margin: 20px auto;
    }

    .forget-password-block {
      margin-top: 15px;
    }
  }
}
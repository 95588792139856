.bs-datetime .input-group-addon {
  background: 0 0;
  padding: 0;
  border-left: 0;
  .btn {
    border: 0;
  }
}

/** allow for tiny mce buttons to use fontawsome */
.mce-tinymce .mce-i-fa {
  font: normal normal normal 14px/1 FontAwesome;
}

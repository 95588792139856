.page-head .page-toolbar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 62px;

  [data-widget=daterange] {
  width: 220px;
  cursor: pointer;

    input[disabled], .form-control[disabled] {
      background-color: white;
      pointer-events: none;
    }
  }

}

@media (min-width: $screen-md-min) {
  .container-fluid {
      padding-left: 30px;
      padding-right: 30px;
  }
}

.scroll-to-top {
  right: 10px;
}

.page-head{
  background: inherit;
}

@media (min-width: 768px) {
  .page-header-menu .navbar-nav > li {
    &.active:before,
    &:hover:before {
      --nav-highlight-color: #3772ff;
      float: left;
      content: '';
      width: 100%;
      display: block;
      height: 3px;
      background: var(--nav-highlight-color);
      position: absolute;
      bottom: 0;
    }

    &:hover:before {
      --nav-highlight-color: #5f8bfd;
    }
  }
}
.page-head .page-title>h1{
  color: #372d2b;
  font-weight: 800;
  text-transform: uppercase;
}

.page-footer{
  background: #3772ff;
}

.page-wrapper .page-wrapper-middle{
  background: #f8f8f8;

}

.page-header .page-header-menu{
  background: #fff;
  border-top: 1px solid #d0ccd0;
  border-bottom: 1px solid #d0ccd0;
}

.page-header .page-header-menu .hor-menu.hor-menu-light .navbar-nav>li>a, .page-header .page-header-menu .hor-menu.hor-menu-light .navbar-nav>li>a>i{
  color: #000;
}

.page-header .page-header-menu .hor-menu.hor-menu-light .navbar-nav>li.active>a, .page-header .page-header-menu .hor-menu.hor-menu-light .navbar-nav>li.active>a:hover, .page-header .page-header-menu .hor-menu.hor-menu-light .navbar-nav>li.current>a, .page-header .page-header-menu .hor-menu.hor-menu-light .navbar-nav>li.current>a:hover{
  background: inherit;
  color: #000;
}
.page-header .page-header-menu .hor-menu .navbar-nav>li.open>a, .page-header .page-header-menu .hor-menu .navbar-nav>li:hover>a, .page-header .page-header-menu .hor-menu .navbar-nav>li>a:active, .page-header .page-header-menu .hor-menu .navbar-nav>li>a:focus, .page-header .page-header-menu .hor-menu .navbar-nav>li>a:hover{
  background: inherit !important;
  color: #000;
}

.page-header .page-header-menu .hor-menu.hor-menu-light .navbar-nav>li.open>a, .page-header .page-header-menu .hor-menu.hor-menu-light .navbar-nav>li:hover>a{
  background: inherit !important;
  color: #000 !important;
}

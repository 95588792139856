.dropdown-menu > li.active > a {
  cursor: default;
}

.page-header .page-header-menu  .navbar-nav .dropdown-menu li> a {
  white-space: nowrap !important;
}

.dropdown-actions {

  > .dropdown-toggle {
    border: 0;
    background: transparent;

    &:hover {
      background: transparent;
    }
  }

  &.open > .dropdown-toggle {
    color: #333;
    background-color: transparent;
    border-color: transparent;
  }
}

.dropdown-menu > li {
  button {
    padding: 8px 16px;
    color: #6f6f6f;
    text-decoration: none;
    display: block;
    clear: both;
    font-weight: 300;
    line-height: 18px;
    white-space: nowrap;
    background: transparent;
    border: 0;
    width: 100%;
    text-align: left;
  }

  &.active:hover button, &.active button, &:hover button {
    background-color: #f6f6f6;
  }

  &.dropdown-header{
    padding: 3px 16px
  }

}

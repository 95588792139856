.switch-container {
    container: switch / inline-size;
}

.switch {
    --switch-width: 6em;
    --switch-height: calc(var(--switch-width) / 3);
    --active-color: #337ab7;
    --gap-size: 3px;
    --bubble-height: calc(var(--switch-height) - 2 * var(--gap-size));
    --animation-time: .25s;
    position: relative;
    display: block;
    width: var(--switch-width);
    height: var(--switch-height);
    border-radius: calc(var(--switch-height) / 2) !important;
    background-color: #aaa;

    &,
    &:before,
    & > span,
    &:after {
        transition: var(--animation-time);
    }

    & > input[type=checkbox] {
        visibility: hidden;
    }

    &:before {
        content: '';
        position: absolute;
        border-radius: calc(var(--bubble-height) / 2);
        top: var(--gap-size);
        bottom: var(--gap-size);
        left: var(--gap-size);
        width: var(--bubble-height);
        background-color: white;
    }
    & > span {
        position: absolute;
        top: 50%;
        left: calc(var(--switch-width) - 1 * var(--gap-size));
        transform: translate(-100%, -50%);
        color: white;
        font-size: 1em;
        padding: 0 .5em;
    }

    &:has(input:active):before {
        width: calc(1.5 * var(--bubble-height));
    }

    &:has(input:checked) {
        background-color: var(--active-color);

        &:before {
            left: calc(var(--switch-width) - var(--gap-size));
            transform: translateX(-100%);
            background-color: white;
        }
        &.danger {
            --active-color: red;
        }
        &.warning {
            --active-color: #f9e491;
            & > span {
                color: var(--active-color);
                filter: brightness(.5);
            }
        }
        &.info {
            --active-color: #65afe1;
        }
        & > span {
            left: var(--gap-size);
            transform: translate(0%, -50%);
        }
    }
}

@container switch (max-width: 6em) {
    .switch {
        --switch-width: 100cqi;

        & > span {
            font-size: calc(min(1em, 0.9vi));
        }
    }
}

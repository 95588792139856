.publishing-post-gallery {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  //@media screen and (min-width: 1000px) {
  //  .fb-link-post-clone {
  //    width: calc(100% / 3);
  //  }
  //}
}

.publishing-post-gallery .cell {
  padding-left: 20px;

  .fb-link-post-clone.post-rejected {
    border: 2px solid #e7505a;
    background-color: rgba(231, 80, 90, 0.05);
  }

  .fb-link-post-clone.post-allowed {
    border: 2px solid #32c5d2;
    background-color: rgba(50, 197, 210, 0.05);
  }
}
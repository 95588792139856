//== Media queries breakpoints
//
//Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
$screen-xs-min:              		480px !default;

// Small screen / tablet
$screen-sm-min:              		768px !default;

// Medium screen / desktop
$screen-md-min:              		992px !default;

// Large screen / wide desktop
$screen-lg-min:             	 	1200px !default;

// Large scree / wider desktop
$screen-lg-med:                     1260px !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              		($screen-sm-min - 1) !default;  // 767px
$screen-sm-max:              		($screen-md-min - 1) !default;  // 991px
$screen-md-max:              		($screen-lg-min - 1) !default;  // 1199px

// ---------------------------------------------------------------------------------------------------------------------
// Bootstrap 4
// ---------------------------------------------------------------------------------------------------------------------

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map-merge(
  (
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
    auto: auto
  ),
  $sizes
);

$zindex-dropdown:                   1000 !default;
$zindex-sticky:                     1020 !default;
$zindex-fixed:                      1030 !default;
$zindex-modal-backdrop:             1040 !default;
$zindex-modal:                      1050 !default;
$zindex-popover:                    1060 !default;
$zindex-tooltip:                    1070 !default;

// Utilities

$displays: none, inline, inline-block, block, table, table-row, table-cell, flex, inline-flex !default;
$overflows: auto, hidden !default;
$positions: static, relative, absolute, fixed, sticky !default;
$fz-post-message: 11px;
$fz-post-title: 16px;
$fz-post-caption: 10px;

.fb-post-summary {
  display: block;
  list-style-type: none;
  padding: 0;
  color: #1d2129;
  width: 100%;
  height: 100%;

  &.loading::after {
    content: "";
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url(/static/lib/metronic/assets/global/img/loading.gif) no-repeat center center;
  }
  
  .badword {
    color: #e12330;
  }
}

.fb-post-summary > li {
  //max-width: 340px;
  width: 340px;
  background-color: white;
  border: 1px solid;
  border-color: #e5e6e9 #dfe0e4 #d0d1d5;
  border-radius: 3px;
  padding: 8px 8px 0;
  margin-bottom: 10px;
  opacity: 1; /* reveal when ready */
  transition: opacity 0.5s ease;

  &.not-visible {
    opacity: 0;/* hide by default */
  }

  &[data-classification="problematic"] {
    border-color: #e12330;
    border-width: 3px;
    background-color: rgba(217, 30, 24, .05);
  }

}

.fb-post-summary .post-message {
  font-size: $fz-post-message;
  font-weight: normal;
  line-height: 1.38;
  overflow: hidden;

  p {
    margin: 6px 0;
  }

  p:first-child {
    margin-top: 0;
  }

  p:last-of-type {
    display: inline;
    margin-bottom: 0;
  }
}

.fb-post-summary .post-body {
  margin-top: 10px;
  border: 1px solid #e9ebee;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .15) inset, 0 1px 4px rgba(0, 0, 0, .1);

  a {
    text-decoration: none;
    color: inherit;
    display: block;
  }
}

.fb-post-summary .post-text {
  padding: 8px 10px;
  max-height: 100px;
}

.fb-post-summary .post-picture {
  position: relative;
  height: 168px;

  &::after {
    border: 1px solid rgba(0, 0, 0, .1);
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  img {
    display: block;
    height: auto;
    width: 100%;
    max-height: 100%;
  }
}

.fb-post-summary .post-title {
  font-family: Georgia, serif;
  letter-spacing: normal;
  font-size: $fz-post-title;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 5px;
  max-height: 110px;
  overflow: hidden;
  word-wrap: break-word;
}

.fb-post-summary .post-caption {
  padding-top: 9px;
  color: #90949c;
  font-size: $fz-post-caption;
  line-height: 1;
  text-transform: uppercase;

  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.fb-post-summary .post-reporting {
  color: #90949c;
  margin-top: 12px;
  border-top: 1px solid #e5e5e5;
  padding-bottom: 4px;
  padding-top: 5px;

  & > div {
    display: flex;
    justify-content: space-between;
  }
}

.fb-post-summary .post-facts {
  padding: 0;
  list-style-type: none;
  color: #7f7f7f;
  font-size: 12px;
  font-weight: bold;
  line-height: 14px;

  li {
    display: inline-block;
    margin-right: 10px;
    padding: 4px 4px 4px 0;
  }
}

.fb-post-summary .post-actions {
  .btn-icon-only {
    height: 30px;
    width: 30px;

  }

  .dropdown-menu .active {
    color: #000;
    background: #eaeaf2;
    cursor: default;
  }
}

.fb-post-summary [data-user-eval="rejected"] .post-actions .btn-eval {
    border-color: #e12330;
    background-color: #e12330;
    color: white;

    &.readonly:hover,
    &.readonly:focus {
      border-color: #e12330;
      background-color: #e12330;
    }
}

.fb-post-summary [data-user-eval="allowed"] .post-actions .btn-eval {
    border-color: #32c5d2;
    background-color: #32c5d2;
    color: white;

    &.readonly:hover,
    &.readonly:focus {
      border-color: #32c5d2;
      background-color: #32c5d2;
    }
}

svg.progress-circle {
  circle.backdrop {
    stroke: #eee;
    fill: transparent;
  }
  circle.progress {
    fill: transparent;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }
  circle.progress.live {
    stroke: #80C9F5;
  }
  circle.progress.forecast {
    stroke: #80C9F577;
  }
  text.progress,
  text.forecast {
    text-anchor: middle;
    dominant-baseline: central;
    font-weight: bold;
    fill: #80C9F5;
  }
  text.progress {
    font-size: 2.5rem;
  }
  text.forecast {
    display: none;
  }
  circle.forecast:hover {
    & ~ text.forecast {
      display: initial;
      pointer-events: none;
    }
    & ~ text.below-45.forecast {
      fill: #00bcd4;
    }
    & ~ text.below-55.forecast {
      fill: #225d65;
    }
    & ~ text.above-55.forecast {
      fill: #fff;
    }
  }
  circle.progress.forecast:hover,
  circle.backdrop.forecast:hover ~ circle.progress.forecast {
    stroke: #00bcd4;
  }
}
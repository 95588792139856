.table-article-parts {

  picture, img {
    display: block;
  }

  .table-row {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-left: -20px;
    margin-right: -20px;
    border-bottom: 1px solid #eee;
    position:relative;

    &:last-child {
      border-bottom: 0 none;
    }

    &.highlight:before{
      content: '';
      position: absolute;
      left: -6px;
      top: 0;
      bottom: 0;
      width: 6px;
      background: #32c5d2;
    }

    &.highlight-warning:before{
      background: #f9e491a3;
    }

  }

  .table-cell {
    padding: 20px;
  }

  .table-cell.part-media {
    width: 100%;

    .preview-container iframe {
      width: 100%;
    }

    picture img {
      width: 100%;
    }

    .embed-provider-logo {
      height: 120px;
    }

    .media-meta {
      margin-top: 10px;
      font-size: 12px;
    }
  }

  .table-cell.part-text {
    flex: 1;

    .part-text-meta {
      border-top: 1px solid #eee;
      padding-top: 10px;
      line-height: 13px;
      color: #9eacb4;
      font-size: 13px;
      font-weight: 400;
    }

    & > div:not([class]) {
      font-style: italic;

      &:before {
        content: "Überleitung";
        background: gray;
        color: white;
        font-size: .8em;
        padding: 2px 5px;
        margin-right: 10px;
      }
    }
  }

  .table-cell.part-actions {
    > ul {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      > li {
        margin-right: 1rem;
      }

      > li:last-child {
        margin-right: 0;
        margin-left: auto;
      }
    }
  }

  @media (min-width: $screen-lg-min) {
    .table-row {
      flex-direction: row;
      flex-wrap: nowrap;
    }

    .table-cell.part-media {
      width: 30%;
    }

    .table-cell.part-actions {
      > ul {
        display: block;

        > li {
          margin-left: unset;
          margin-bottom: 1rem;
        }
      }
    }
  }
}

.flex-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .flex-grid-item {
    margin-bottom: 10px;
    flex: 0 1 100%;
    position: relative;
  }

  @media (min-width: $screen-sm-min) {
    .flex-grid-item { flex: 0 1 calc(100% / 2 - 5px); }
  }

  @media (min-width: $screen-md-min) {
    .flex-grid-item { flex: 0 1 calc(100% / 3 - 10px); }
  }

  @media (min-width: $screen-lg-min) {
    .flex-grid-item { flex: 0 1 calc(100% / 4 - 10px); }
  }

  @media (min-width: 1779px) {
    .flex-grid-item { flex: 0 1 calc(100% / 5 - 10px); }
  }
}

.flex-grid .flex-grid-item {
  > .dropdown-actions,
  > .btn-action {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
  }

  > .btn-action {
    border: 0 none;
    background: none;
    color: #7f7f7f;
    padding: 12px;
  }

  > .btn-action:hover {
    color: #333;
  }
}
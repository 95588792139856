table td > i.green {
  color: #32c5d2;
}

.table {
  &.table-striped-columns th:nth-of-type(even),
  &.table-striped-columns td:nth-of-type(even) {
    background-color: hsl(0, 0%, 98%);
  }
}

.table-toolbar {
  button, .select2 {
    display: inline-block;
  }
}

.table-toolbar .filter-btn > label {
  display: inline;
}

.table .vertical-favicon {
  height: 16px;
}

[data-datatables] tr.dt-hidden {
  display: none;
}

.table.table-fixed {
  table-layout: fixed;
}

.table.table-wrap-word td {
  word-wrap: break-word;
}

.table-notifications tr.unread td {
  font-weight: bold;
}

.table thead tr th i {
  font-weight: 600;
}

.table.collapsed .collapsed-hidden {
  display: none;
}

.table td > .progress {
  margin-bottom: 0;

  .progress-bar {
    padding: 0 5px;
    max-width: 100%;
    color: #333;
    text-overflow: clip;
    white-space: nowrap;
  }
}

.table[data-datatables] {
  > tfoot > tr > td,
  > tfoot > tr > th {
    padding: 8px;
  }
}

.table.table-borderless {

  > tbody > tr > td,
  > tbody > tr > th {
    border-top: none 0;
  }
}

.table thead tr th {
  vertical-align: top;
}

.table thead tr + tr th {
  border-top: 1px solid #e7ecf1;
}

.portlet.light .dataTables_wrapper .dt-buttons {
  margin-top: 0;
}

.dataTables_wrapper .dt-buttons {
  float: none;
}

.table td > .display-flex {
  display: flex;
  justify-content: space-between;
}

.table td .link-icons {
  white-space: nowrap;

  a {
    margin-left: 5px;
  }
}

.table td > pre {
  white-space: pre-wrap;
}

.table .tr-inline-hover {
  display: none;
}
.table tr:hover .tr-inline-hover {
  display: inline;
}

.table-sticky {
  position: relative;

  .sticky-col-left {
    position: sticky;
    background-color: white;
    left: 0;
  }
}

// adjust for bootstrap3 not coming with table-sm
.table-sm {
  th,
  td {
    padding: .3rem !important;
  }
}

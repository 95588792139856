body.loading-overlay::after {
  content: "";
  display: block;
  position: fixed;
  background: rgba(255,255,255,.95) url(/static/lib/metronic/assets/global/img/loading.gif) no-repeat center center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: all .3s;
}
.portlet-title .caption .vertical-icon {
  height: 40px;
}

.portlet-body .table-toolbar {
  .select2-container--bootstrap {
    display: inline-block;
  }

  [data-widget=daterange], .date-filter {
    display: inline-table;
    vertical-align: middle;
  }

  [data-widget=daterange] {
    width: 220px;
    cursor: pointer;

    input[disabled], .form-control[disabled] {
      background-color: white;
      pointer-events: none;
    }
  }

  // Prevent FOUC
  [data-widget=select2] {
    visibility: hidden;
    width: 0px;
  }


  [data-widget=datepicker] {
    display: inline-table;
    vertical-align: middle;
  }
}

.portlet-body form + .table {
  margin-top: 40px;
}

.portlet-body .filter-bar > * {
  margin-bottom: 4px;
}
@media (min-width: 768px) {
  .portlet-body .filter-bar {
    text-align: right;
  }
}

.portlet-body .table-toolbar [data-tabs] {
  margin-bottom: 4px;
  padding: 0;
  list-style-type: none;

  > li {
    display: inline-block;
  }

  > .active a {
    cursor: default;
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
  }
}

.portlet-body .ordering-bar {
  text-align: right;

  .form-control {
    width: auto;
    display: inline-block;
  }
}

.portlet-body .article-preview {
  max-width: 728px;
  margin: 0 auto;

  .part-image {
    margin-bottom: 15px;
  }
  
  .part-embedded {
    position: relative;
    overflow: hidden;
    margin-bottom: 15px;
  }

  .media-meta {
    color: #9f9f9f;
    font-size: 11px;
    line-height: 1;
  }

  img + .media-meta {
    margin-top: 5px;
  }

  p {
    margin-bottom: 25px;
    text-align: justify;
    font-size: 18px;
  }
}

.portlet > .portlet-title > .caption.nav-tabs {
  padding-top: 0;
  padding-bottom: 0;
  float: left;
  line-height: 18px;

  .caption-subject {
    font-size: 16px;
  }
  /** fix jumping of active bar */
  &>li:not(.active):not(:hover){
    background: 0 0;
    border-bottom: 4px solid transparent;
    position: relative;
  }

}


.portlet > .portlet-title.portlet-footer {
  border-top: 1px solid #eee;
  border-bottom: none;
  margin-top: 10px;
  margin-bottom: 0;
}

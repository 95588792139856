.btn-blue {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff
}

.btn-blue.focus,.btn-primary:focus {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc
}

.btn-blue.active,.btn-blue:active,.btn-blue:hover,.open>.btn-blue.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf
}

.btn-blue.active.focus,.btn-blue.active:focus,.btn-blue.active:hover,.btn-blue:active.focus,.btn-blue:active:focus,.btn-blue:active:hover,.open>.btn-blue.dropdown-toggle.focus,.open>.btn-blue.dropdown-toggle:focus,.open>.btn-blue.dropdown-toggle:hover {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf
}

.btn-blue.disabled.focus,.btn-blue.disabled:focus,.btn-blue.disabled:hover,.btn-blue[disabled].focus,.btn-blue[disabled]:focus,.btn-blue[disabled]:hover,fieldset[disabled] .btn-blue.focus,fieldset[disabled] .btn-blue:focus,fieldset[disabled] .btn-blue:hover {
    background-color: #007bff;
    border-color: #007bff
}
